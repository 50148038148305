import React, { useContext } from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import { Formik, Form } from 'formik';
import { Typography } from '@mui/material';
import { AppContext } from '../context/AppContext';

const getMicroloanInitialInfo = async (reference) => {
  const response = await fetch(
    `${process.env.REACT_APP_APIHOST}/microloans/findbyref/${reference}`,
  );
  const json = await response.json();
  return json.data;
};

const MicroLoanLanding = () => {
  const params = useParams();
  // let [searchParams, setSearchParams] = useSearchParams();
  // const bank = searchParams.get('bank');
  const { loading, setLoading, bankName, setBankName } = useContext(AppContext);
  const { reference } = params;
  const [initialValues, setInitialValues] = React.useState({
    requesterNID: '',
    firstName: '',
    lastName: '',
  });

  React.useEffect(() => {
    // Call Bank service to get loan's info
    const fetchData = async () => {
      const loan = await getMicroloanInitialInfo(reference);
      setInitialValues(loan);
    };

    fetchData();
  }, [reference]);

  return (
    <Box sx={{ textAlign: 'center' }}>
     <h1>{`Welcome to ${bankName}`}</h1>
    <p>Thank you for your interest to do loan with us</p>
    <p>Your loan reference: {reference}</p>
    <Alert severity="info">
      <Box sx={{ textAlign: 'left' }}>
        <Box sx={{ mb: 1 }}>
          Request Amount: {initialValues.requestAmount}
        </Box>
        <Box sx={{ mb: 1 }}>
          Request Term: {initialValues.requestTerm} months
        </Box>
        <Box sx={{ mb: 1 }}>Requester NID: {initialValues.requesterNID}</Box>
      </Box>
    </Alert>
    <Divider sx={{ my: 8 }} />
    <Box sx={{ px: 8, pb: 4 }}>
      <Typography>ส่งคำขอกู้เงิน สำเร็จ</Typography>
    </Box>
    <Divider sx={{ my: 2 }} />
    <Box sx={{ px: 8, pb: 4 }}>
      {/* <Typography>กรุณารอสักครู่</Typography> */}
      {/* <Typography>{`ระบบจะกลับไปที่ Micro Loan ภายใน ${counter}..`}</Typography> */}

      <Button
        onClick={() => {
          // navigate(`/loans/create/success`);
          window.flutter_inappwebview.callHandler('sendToApp', 'close');
        }}
        color="primary"
        variant="contained"
      >
        กลับสู่โครงการกู้ยืมเงินชราภาพ
      </Button>
    </Box>
  </Box>
  );
};

export default MicroLoanLanding;
