import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import { AppContextProvider } from './context/AppContext';
import MicroLoanLanding from './pages/MicroloanLanding';
import MicroloanSuccess from './pages/MicroloanSuccess';

import './App.css';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const Layout = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContextProvider>
        <Router>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/deeplinks/:reference"
                element={<MicroLoanLanding />}
              />
              <Route
                path="/deeplinks/:reference/success"
                element={<MicroloanSuccess />}
              />
            </Route>
          </Routes>
        </Router>
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
