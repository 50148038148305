import React, { useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import { Formik, Form } from 'formik';
import { Typography } from '@mui/material';
import { AppContext } from '../context/AppContext';

const getMicroloanInitialInfo = async (reference) => {
  const response = await fetch(
    `${process.env.REACT_APP_APIHOST}/microloans/findbyref/${reference}`,
  );
  const json = await response.json();
  return json.data;
};

const submitMicroloan = async (values) => {
  // const body = JSON.stringify({ requesterNID: values.requesterNID, firstName: values.firstName, lastName:  values.lastName, status: values.status })

  return axios.put(`${process.env.REACT_APP_APIHOST}/microloans/${values.id}`, {
    requesterNID: values.requesterNID,
    firstName: values.firstName,
    lastName: values.lastName,
    status: values.status,
  });

  // return fetch(`${process.env.REACT_APP_APIHOST}/microloans/${values.id}`, {
  //   method: 'PUT',
  //   body
  // })
};

const MicroLoanLanding = () => {
  const navigate = useNavigate();
  const params = useParams();
  // let [searchParams, setSearchParams] = useSearchParams();
  // const bank = searchParams.get('bank');
  const { loading, setLoading, bankName, setBankName } = useContext(AppContext);
  const { reference } = params;
  const [initialValues, setInitialValues] = React.useState({
    requesterNID: '',
    firstName: '',
    lastName: '',
  });

  React.useEffect(() => {
    // Call Bank service to get loan's info
    const fetchData = async () => {
      setLoading(true);
      const loan = await getMicroloanInitialInfo(reference);
      setBankName(loan?.bankName ?? '');
      setInitialValues(loan);
      setLoading(false);
    };

    fetchData();
  }, [reference]);

  const onSubmit = async (values) => {
    console.log(`submit`, { values });
    try {
      await submitMicroloan({ ...values, status: 'user_confirmed' });
      navigate(`/deeplinks/${reference}/success`);
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.requesterNID)
      errors.requesterNID = 'กรุณากรอกหมายเลขบัตรประชาชน';
    else if (values.requesterNID && values.requesterNID.length !== 13)
      errors.requesterNID = 'เลขที่บัตรประชาชนต้องมี 13 หลักเท่านั้น';
    else if (values.requesterNID && !/^\d+$/.test(values.requesterNID)) {
      errors.requesterNID = 'กรุณากรอกเฉพาะตัวเลขเท่านั้น';
    }

    if (!values.firstName) errors.firstName = 'กรุณากรอกชื่อ';
    if (!values.lastName) errors.lastName = 'กรุณากรอกนามสกุล';

    return errors;
  };

  return loading ? (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ textAlign: 'center', pb: 8 }}>
      <h1>{`Welcome to ${bankName}`}</h1>
      <p>Thank you for your interest to do loan with us</p>
      <p>Your loan reference: {reference}</p>
      <Alert severity="info">
        <Box sx={{ textAlign: 'left' }}>
          <Box sx={{ mb: 1 }}>
            Request Amount: {initialValues.requestAmount}
          </Box>
          <Box sx={{ mb: 1 }}>
            Request Term: {initialValues.requestTerm} months
          </Box>
          <Box sx={{ mb: 1 }}>Requester NID: {initialValues.requesterNID}</Box>
        </Box>
      </Alert>
      <Divider sx={{ my: 8 }} />
      <Box sx={{ px: 8, pb: 4 }}>
        <Typography>
          กรุณาตรวจสอบข้อมูล ชื่อนามสกุล
          เลขบัตรประชาชนให้ถูกต้องก่อนดำเนินการต่อไป
          ถ้าไม่ถูกต้องกรุณาแก้ไขให้ถูกต้อง
        </Typography>
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, isValid, isSubmitting }) => {
          return (
            <Form>
              <TextField
                fullWidth
                margin="normal"
                id="requesterNID"
                name="requesterNID"
                label="หมายเลขบัตรประชาชน"
                value={values.requesterNID}
                onChange={handleChange}
                error={Boolean(errors.requesterNID)}
                helperText={errors.requesterNID}
              />
              <TextField
                fullWidth
                margin="normal"
                id="firstName"
                name="firstName"
                label="ชื่อ"
                value={values.firstName || ''}
                onChange={handleChange}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
              <TextField
                fullWidth
                margin="normal"
                id="lastName"
                name="lastName"
                label="นามสกุล"
                value={values.lastName || ''}
                onChange={handleChange}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />

              <Divider sx={{ my: 2 }} />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                ขอกู้เงิน
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default MicroLoanLanding;
