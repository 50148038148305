import React, { useState, useEffect } from 'react';

const AppContext = React.createContext();

function AppContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [bankName, setBankName] = useState('');

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        bankName,
        setBankName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContext, AppContextProvider };
